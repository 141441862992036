import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from './localStorage'
import { AccessToken } from 'types/access-token'

export const sessionKey = 'session'

export const getSession = (): AccessToken | undefined => {
  const sessionString = getLocalStorage(sessionKey)
  return sessionString ? JSON.parse(sessionString) : undefined
}

export const storeSession = (session: AccessToken) => {
  setLocalStorage(sessionKey, JSON.stringify(session))
}

export const destroySession = () => {
  removeLocalStorage(sessionKey)
}
