import { useQuery } from 'react-query'

import { getConnection } from 'services/connection'

function useConnection() {
  const { data: connection } = useQuery('connection', getConnection, {
    retry: false,
  })
  return connection
}

export { useConnection }
