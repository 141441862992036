import { css } from '@emotion/react'
import { ReactNode, Component } from 'react'

const errorCSS = css`
  background-color: red;
  color: #fff;
  font-size: 15px;
  left: 0;
  line-height: 2em;
  margin: 0;
  padding: 6px 20px;
  position: fixed;
  right: 0;
  top: 0;
  > p {
    margin: 0;
  }
`

export const SomethingWentWrong = () => (
  <div css={errorCSS}>
    <p
      style={{
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '30px',
      }}
    >
      Something went wrong
    </p>
    <p>
      We're sorry — something's gone wrong. Please refresh your browser, or hit{' '}
      <strong>Refresh</strong> below.
    </p>
    <p
      onClick={() => window.location.reload()}
      style={{
        cursor: 'pointer',
        fontWeight: 600,
        marginTop: 6,
        textDecoration: 'underline',
      }}
    >
      Refresh
    </p>
  </div>
)

type Props = {
  children: ReactNode
}

type State = {
  error?: Error
}

class ErrorBoundary extends Component<Props, State> {
  state = { error: undefined }

  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({ error })
  }

  render() {
    return this.state.error ? <SomethingWentWrong /> : this.props.children
  }
}

export default ErrorBoundary
