import React, {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useQueryClient } from 'react-query'

import {
  destroySession as removeSessionFromStorage,
  getSession,
  storeSession,
} from '../utils/auth'
import { AccessToken } from 'types/access-token'

export type AuthContextType = {
  createSession: (session: AccessToken) => void
  destroySession: () => void
  isAuthenticated: boolean
  session: AccessToken | undefined
}

const AuthContext = React.createContext<AuthContextType>({
  createSession: () => undefined,
  destroySession: () => undefined,
  isAuthenticated: false,
  session: undefined,
})

type Props = {
  children: ReactNode
  session?: AccessToken
}

const AuthProvider = (props: Props) => {
  const queryClient = useQueryClient()
  const [session, setSession] = useState<AccessToken | undefined>(props.session)
  const [setupFinished, setSetupFinished] = useState(false)

  const createSession = useCallback(
    (session: AccessToken) => {
      setSession(session)
      storeSession(session)
    },
    [setSession]
  )
  const destroySession = useCallback(() => {
    setSession(undefined)
    removeSessionFromStorage()
    queryClient.clear()
  }, [setSession, queryClient])
  const value = useMemo(
    () => ({
      createSession,
      destroySession,
      isAuthenticated: !!session,
      session,
    }),
    [createSession, destroySession, session]
  )

  useLayoutEffect(() => {
    const session = getSession()
    if (session) {
      createSession(session)
    }
    setSetupFinished(true)
  }, [createSession, destroySession])

  if (!setupFinished) {
    return null
  }

  return <AuthContext.Provider value={value} {...props} />
}

export { AuthProvider, AuthContext }
