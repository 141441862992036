import axios from 'axios'

import config from 'config'
import { destroySession, getSession } from 'utils/auth'

const client = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

const responseErrorInterceptor = (error: any) => {
  const session = getSession()
  if (session && error && error.response && error.response.status === 401) {
    destroySession()
    window.location.href = '/'
  }
  return Promise.reject(error)
}

const responseDataInterceptor = (response: any) => {
  return response
}

const requestAuthInterceptor = (config: any) => {
  const session = getSession()
  if (session && !config.headers.Authorization) {
    config.headers.Authorization = session.token
  }
  return config
}

client.interceptors.request.use(requestAuthInterceptor)

client.interceptors.response.use(
  responseDataInterceptor,
  responseErrorInterceptor
)

export default client
