export interface Booking {
  id: number
  name: string
  email: string
  phone: string
  payed: boolean
  detail: string
  sended: boolean
  deposit: number
  security: string
  pending: boolean
  toPay: number
  total: number
}

export enum BookingFilter {
  Active = 'active',
  Canceled = 'canceled',
  Pending = 'pending',
}
