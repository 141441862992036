export const getLocalStorage = (key: string) => {
  try {
    return window.localStorage.getItem(key)
  } catch (error) {
    return undefined
  }
}

export const setLocalStorage = (key: string, value: string) => {
  try {
    window.localStorage.setItem(key, value)
  } catch (error) {
    // ignored
  }
}

export const removeLocalStorage = (key: string) => {
  try {
    window.localStorage.removeItem(key)
  } catch (error) {
    // ignored
  }
}
