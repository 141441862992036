import { QueryClientProvider, QueryClient } from 'react-query'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import {
  CommandButton,
  DetailsList,
  Dropdown,
  IColumn,
  Persona,
  PersonaSize,
  PrimaryButton,
  SelectionMode,
  TextField,
} from '@fluentui/react'
import { css } from '@emotion/css'
import { NeutralColors, DefaultEffects } from '@fluentui/theme'
import { useState } from 'react'

import ErrorBoundary from 'components/ErrorBoundary'
import { AuthProvider } from 'components/AuthProvider'
import { useAuth } from 'data/use-auth'
import { useConnection } from 'data/use-connection'
import { useSignin, useSignout } from 'data/use-signin'
import { getImageUrl } from 'utils/user'
import { useBookings } from 'data/use-bookings'
import { Booking, BookingFilter } from 'types/booking'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
    mutations: {
      // mutation options
    },
  },
})

function UnauthenticatedApp() {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const signin = useSignin()

  const handleSubmit = () => {
    signin(username, password)
  }

  return (
    <div
      className={css`
        background-color: #ffff;
        background-image: url(https://turbookings.info/app/splash.jpeg);
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: flex-end;
        height: 100%;
        flex-direction: column;
        width: 100%;
      `}
    >
      <form
        className={css`
          background-color: #fafafa;
          box-shadow: ${DefaultEffects.elevation4};
          padding: 28px;
          > * {
            margin-bottom: 8px;
          }
        `}
        onSubmit={(e) => {
          e.stopPropagation()
          e.preventDefault()
          handleSubmit()
        }}
      >
        <TextField
          name="username"
          placeholder="type here"
          value={username}
          type="text"
          onChange={(event) => setUsername(event.currentTarget.value)}
        />
        <TextField
          name="password"
          placeholder="type here"
          value={password}
          type="password"
          onChange={(event) => setPassword(event.currentTarget.value)}
        />
        <PrimaryButton type="submit">Login</PrimaryButton>
      </form>
    </div>
  )
}

function AuthenticatedApp() {
  const [filter, setFilter] = useState<BookingFilter>(BookingFilter.Active)
  const signout = useSignout()
  const connection = useConnection()
  const { data: bookings = [] } = useBookings(filter)

  if (!connection) {
    return null
  }

  const imageUrl = getImageUrl(connection.user.image)
  const columns: IColumn[] = [
    {
      key: 'Actions',
      name: 'Actions',
      fieldName: 'actions',
      isResizable: true,
      isPadded: true,
      minWidth: 100,
      onRender: (booking: Booking) => {
        return (
          <CommandButton
            style={{
              fontSize: 11,
              height: 14,
              padding: 0,
            }}
            iconProps={{ iconName: 'Add' }}
            menuProps={{
              onItemClick: (_, item) => {
                switch (item?.key) {
                  case 'email': {
                    window.location.href = 'mailto: ' + booking.email
                    break
                  }
                  case 'phone': {
                    window.location.href = 'tel: ' + booking.phone
                    break
                  }
                  case 'whatsapp': {
                    window.location.href = `https://wa.me/${booking.phone.replace(
                      /\D/g,
                      ''
                    )}`
                    break
                  }
                }
              },
              items: [
                {
                  key: 'email',
                  text: 'Email message',
                  iconProps: { iconName: 'Mail' },
                },
                {
                  key: 'phone',
                  text: 'Phone',
                  iconProps: { iconName: 'Phone' },
                },
                {
                  key: 'whatsapp',
                  text: 'Whats app message',
                  iconProps: { iconName: 'Phone' },
                },
              ],
            }}
          />
        )
      },
    },
    {
      key: 'name',
      name: 'Customer',
      fieldName: 'name',
      isResizable: true,
      isPadded: true,
      minWidth: 130,
    },
    {
      key: 'detail',
      name: 'Detail',
      fieldName: 'detail',
      isResizable: true,
      isPadded: true,
      minWidth: 240,
    },
    {
      key: 'status',
      name: 'Status',
      fieldName: 'status',
      isResizable: true,
      isPadded: true,
      minWidth: 130,
      onRender: (booking: Booking) => {
        return (
          <div
            className={css`
              display: inline-flex;
              > * {
                margin-right: 8px;
              }
            `}
          >
            {booking.payed && (
              <div
                className={css`
                  background: ${NeutralColors.gray10};
                  font-size: 8px;
                  border-radius: 8px;
                  text-transform: uppercase;
                  display: inline-flex;
                  padding: 3px 8px;
                `}
              >
                Paid
              </div>
            )}
            {booking.sended && (
              <div
                className={css`
                  background: ${NeutralColors.gray10};
                  font-size: 8px;
                  border-radius: 8px;
                  text-transform: uppercase;
                  display: inline-flex;
                  padding: 3px 8px;
                `}
              >
                Sent
              </div>
            )}
          </div>
        )
      },
    },
    {
      key: 'phone',
      name: 'phone',
      fieldName: 'phone',
      isResizable: true,
      isPadded: true,
      minWidth: 230,
    },
    {
      key: 'email',
      name: 'email',
      fieldName: 'email',
      isResizable: true,
      isPadded: true,
      minWidth: 230,
    },
    {
      key: 'country',
      name: 'Country',
      fieldName: 'country',
      isResizable: true,
      isPadded: true,
      minWidth: 130,
    },
    {
      key: 'datetime',
      name: 'Date',
      fieldName: 'datetime',
      minWidth: 200,
      isResizable: true,
      data: 'number',
      isPadded: true,
    },
  ]

  return (
    <div>
      <div
        className={css`
          background: ${NeutralColors.gray10};
          box-shadow: ${DefaultEffects.elevation4};
          display: flex;
          justify-content: space-between;
          padding: 12px;
        `}
      >
        <Persona
          text={connection.user.name}
          imageUrl={imageUrl}
          size={PersonaSize.size40}
          secondaryText={connection.company.name}
          imageShouldStartVisible
        />
        <PrimaryButton onClick={signout}>Logout</PrimaryButton>
      </div>
      <div
        className={css`
          padding: 16px;
        `}
      >
        <Dropdown
          options={[
            {
              text: 'All',
              key: BookingFilter.Active,
            },
            {
              text: 'Pending',
              key: BookingFilter.Pending,
            },
            {
              text: 'Canceled',
              key: BookingFilter.Canceled,
            },
          ]}
          key={filter}
          selectedKey={filter}
          onChanged={(e) => setFilter(e.key as BookingFilter)}
          placeholder="Filter bookings"
        />
        <DetailsList
          compact
          selectionMode={SelectionMode.none}
          columns={columns}
          items={bookings}
        />
      </div>
    </div>
  )
}

const App = () => {
  const { isAuthenticated } = useAuth()

  return (
    <ErrorBoundary>
      <Switch>
        <Route
          path="/"
          component={isAuthenticated ? AuthenticatedApp : UnauthenticatedApp}
        />
      </Switch>
    </ErrorBoundary>
  )
}

export default () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}
