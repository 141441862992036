import { useCallback } from 'react'

import { useAuth } from './use-auth'
import { signin, signout } from 'services/auth'

function useSignin() {
  const { createSession } = useAuth()
  const handleSignin = useCallback(
    async (username: string, password: string) => {
      try {
        const accessToken = await signin(username, password)
        createSession(accessToken)
      } catch (error) {
        // ignore
      }
    },
    [createSession]
  )

  return handleSignin
}

function useSignout() {
  const { destroySession } = useAuth()

  const handleSignout = useCallback(async () => {
    try {
      await signout()
      destroySession()
    } catch (error) {
      // ignore
    }
  }, [destroySession])

  return handleSignout
}

export { useSignin, useSignout }
