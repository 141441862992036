import client from './client'
import { AccessToken } from 'types/access-token'

export async function signin(
  username: string,
  password: string
): Promise<AccessToken> {
  const { data } = await client.post('/v2/signin', { username, password })
  return data
}

export async function signout(): Promise<void> {
  const { data } = await client.post('/v2/signout')
  return data
}
