import { useQuery } from 'react-query'

import { getBookings } from 'services/booking'
import { BookingFilter } from 'types/booking'

function useBookings(filter: BookingFilter) {
  return useQuery(['bookings', filter], () => getBookings(filter), {
    retry: false,
  })
}

export { useBookings }
